import $ from "jquery";

const ImageHelper = function () {
    let self = this;
    let styleRules = {};
    let templates = {
        "2x": ["(-webkit-min-device-pixel-ratio: 1.5)", "(min-resolution: 192dpi)", "(min-device-pixel-ratio: 1.5)", "(min-resolution: 1.5dppx)"],
        "3x": ["(-webkit-min-device-pixel-ratio: 3)", "(min-resolution: 384dpi)", "(min-device-pixel-ratio: 3)", "(min-resolution: 3dppx)"],
    };

    this.addSimple = function (imageSrc, media, id) {
        let style = self.buildRule(id, imageSrc);

        self.addRule(media, style);
    };

    this.addRetina = function (imageData, media, id) {
        let currentRules = templates[imageData[1]].slice();
        let patchedRules = currentRules;
        let style = self.buildRule(id, imageData[0]);

        if (media !== "default") {
            patchedRules = $.map(currentRules, function (ele, i) {
                return ele + " and " + media;
            });
        }

        media = patchedRules.join(",");

        self.addRule(media, style);
    };

    this.buildRule = function (id, src) {
        return "#" + id + '{background-image: url("' + src + '");}';
    };

    this.addRule = function (media, rule) {
        let $styleTag = styleRules[media];
        let styleTagData;
        let rules = "";

        if (media === "default") {
            rules = rule + " ";
        } else {
            rules = "@media " + media + "{" + rule + "}";
        }

        if (!$styleTag) {
            styleRules[media] = $("<style>").text(rules).appendTo("head");
        } else {
            styleTagData = $styleTag.text();
            styleTagData = styleTagData.substring(0, styleTagData.length - 2) + " }" + rule + "}";
            $styleTag.text(styleTagData);
        }
    };

    this.retinaCover = function ($el) {
        if ($el.length <= 0) {
            return;
        }
        return $el.each(function () {
            let $block = $(this);
            let $items = $block.children("[data-srcset]");
            let id = "bg-stretch" + Date.now() + (Math.random() * 1000).toFixed(0);

            if ($items.length) {
                $block.attr("id", id);

                $items.each(function () {
                    let $item = $(this);
                    let data = $item.data("srcset").split(", ");
                    let media = $item.data("media") || "default";
                    let dataLength = data.length;
                    let itemData;
                    let i;

                    for (i = 0; i < dataLength; i++) {
                        itemData = data[i].split(" ");

                        if (itemData.length === 1) {
                            self.addSimple(itemData[0], media, id);
                        } else {
                            self.addRetina(itemData, media, id);
                        }
                    }
                });
            }

            $items.detach();
        });
    };
};

export default ImageHelper;
