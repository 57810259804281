class AppStore {
    constructor() {
        if (!AppStore.instance) {
            this._data = [];
            AppStore.instance = this;
        }

        return AppStore.instance;
    }

    add(item) {
        this._data.push(item);
    }

    get(id) {
        return this._data.find((d) => d.id === id);
    }

    getAll() {
        return this._data;
    }

    update(id, item) {
        this._data = this._data.map((data_item) => {
            if (data_item.id === id) {
                data_item = Object.assign(data_item, item);
            }
            return data_item;
        });
        return this.get(id);
    }
}

const instance = new AppStore();
Object.seal(instance);

export default instance;
