import $ from "jquery";

const StyleGenerator = function () {
    const $styles = $(".js-style-generator");
    const $styleGenerator = $("#js-style-generated-styles");

    function init() {
        if ($styleGenerator.length > 0) {
            console.log("run styles!");
            getStyles();
        }
    }

    function getStyles() {
        let styles = ``;
        $styles.each(function () {
            let getStyles = $(this).data("style");
            const guid = "style-generator-" + getUniqueId();
            $(this).addClass(guid);
            styles += `
                .${guid} {
                    ${getStyles}
                }
            `;
        });
        $styleGenerator.html(styles);
    }

    function getUniqueId() {
        return (performance.now().toString(36) + Math.random().toString(36)).replace(/\./g, "");
    }

    init();
};

export default StyleGenerator;
