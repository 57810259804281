import store from "./app-store";

const AppSettings = function () {
    const settings = {
        id: "settings",
        scroll_location: window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0,
        animations_ran: false,
    };
    store.add(settings);
};

export default AppSettings;
