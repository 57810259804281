import apputils from "./app-utils";
import AppSettings from "./app-settings";
import store from "./app-store";
import ImageHelper from "./components/image-helper";
import StyleGenerator from "./components/style-generator";

(function ($) {
    apputils.handleIncludes();
    AppSettings();
    let imageHelper = new ImageHelper();
    imageHelper.retinaCover($(".js-bg-cover"));
    StyleGenerator();
    const allStoreItems = store.getAll();
    console.log(allStoreItems);
})(jQuery);
