class AppUtils {
    constructor() {
        if (!AppUtils.instance) {
            this._data = {};
            AppUtils.instance = this;
        }

        return AppUtils.instance;
    }

    handleIncludes() {
        if (!String.prototype.includes) {
            String.prototype.includes = function () {
                return String.prototype.indexOf.apply(this, arguments) !== -1;
            };
        }
    }

    baseName(str) {
        var base = new String(str).substring(str.lastIndexOf("/") + 1);

        if (base.lastIndexOf(".") != -1) base = base.substring(0, base.lastIndexOf("."));

        return base;
    }
}

const instance = new AppUtils();
Object.seal(instance);

export default instance;
